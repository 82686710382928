/* eslint-disable max-len */
/* eslint-disable indent */
import validator from "validator";
import {
    invalidCharacters,
    invalidFieldLength,
    invalidRegisterCharacters,
} from "../components/form/Form";
import AccountErrors from "../models/accountErrors";
import RegistrationErrors from "../models/registrationErrors";
import PasswordMatchStates from "../models/passwordMatchStates";
import EnvironmentService from "./environment.service";
import axios from "axios";
import { useAuth0 } from "@auth0/auth0-react";

export default class AccountService {
    static auth0BaseUrl = EnvironmentService.getEnvironmentVariable('IDENTITY_API');
    static clientCredentials = {
        grant_type: 'client_credentials',
        client_id: EnvironmentService.getEnvironmentVariable('OAUTH_API_CLIENT_ID'),
        client_secret: EnvironmentService.getEnvironmentVariable('OAUTH_SECRET'),
        audience: `${this.auth0BaseUrl}/api/v2/`
    };

    static async getToken() {
        const url = `${this.auth0BaseUrl}/oauth/token`;
        try {
            const response = await axios.post(url, this.clientCredentials, {
                headers: { 'Content-Type': 'application/json' }
            });
            return response.data.access_token;
        } catch (error) {
            console.error('Error fetching Auth0 management API token', error.response ? error.response.data : error);
            throw error;
        }
    }

    static async makeAuth0Request(endpoint, method, body) {
        const token = await this.getToken();
        const url = `${this.auth0BaseUrl}/api/v2${endpoint}`;

        const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
            'Accept': 'application/json'
        };

        try {
            const response = await axios({
                method,
                url,
                data: body,
                headers
            });
            return response.data;
        } catch (error) {
            console.error(`Error ${method} Auth0 request:`, error.response ? error.response.data : error);
            throw new Error(error.response ? error.response.data.message : 'Failed to make Auth0 request.');
        }
    }

    static async createAuth0User(user) {
        const body = {
            connection: 'choices',
            email: user.email,
            password: user.password,
            given_name: user.firstName,
            family_name: user.lastName,
            user_metadata: {
                client_id: user.clientId,
                domain_id: user.domainId,
                domain_url: user.domain_url,
                state: user.state,
                is_subscribed: user.isSubscribed
            },
            verify_email: false
        };
        return await this.makeAuth0Request('/users', 'post', body);
    }

    static async editAuth0User(email, firstName, lastName, state) {
        const userId = localStorage.getItem('choices-userId');
        const body = {
            email,
            given_name: firstName,
            family_name: lastName,
            user_metadata: {
                state: state
            }
        };
        return await this.makeAuth0Request(`/users/${userId}`, 'patch', body);
    }

    static async subscribe(status) {
        const userId = localStorage.getItem('choices-userId');
        const body = {
            user_metadata: {
                is_Subscribed: status
            }
        };
        return await this.makeAuth0Request(`/users/${userId}`, 'patch', body);
    }

    static async updatePassword(password) {
        const userId = localStorage.getItem('choices-userId');
        const body = { password };
        return await this.makeAuth0Request(`/users/${userId}`, 'patch', body);
    }

    static async getAuth0UserDetails(userId) {
        return await this.makeAuth0Request(`/users/${userId}`, 'get');
    }

    static getAccountUpdateRules(
        account,
        firstName,
        lastName,
        references,
        state
    ) {
        return [
            {
                boolComparison:
                    firstName.length <= 0 ||
                    firstName.length > 90 ||
                    /[^a-zA-Z-' ]+$/.test(firstName),
                errorMsg: AccountErrors.FIRST_NAME_ERR,
                inputReference: references.firstName,
            },
            {
                boolComparison:
                    lastName.length <= 0 ||
                    lastName.length > 90 ||
                    /[^a-zA-Z-' ]+$/.test(lastName),
                errorMsg: AccountErrors.LAST_NAME_ERR,
                inputReference: references.lastName,
            },
            {
                boolComparison:
                    account.accountEmail && !validator.isEmail(account.accountEmail),
                errorMsg: AccountErrors.EMAIL_ERR,
                inputReference: references.email,
            },
            {
                boolComparison: state === "",
                errorMsg: AccountErrors.STATE_ERR,
                inputReference: references.state,
            },
            {
                boolComparison:
                    account.oldPassword &&
                    account.oldPasswordMatch !== PasswordMatchStates.VERIFIED_MATCH,
                errorMsg: AccountErrors.OLDPASS_ERR,
                inputReference: references.oldPass,
            },
            {
                boolComparison:
                    account.newPassword &&
                    !this.enforcePasswordRules(account.newPassword),
                errorMsg: AccountErrors.NEWPASS_ERR,
                inputReference: references.newPass,
            },
            {
                boolComparison: account.newPassword && !account.newPasswordMatch,
                errorMsg: AccountErrors.CONFIRMPASS_ERR,
                inputReference: references.confirmPass,
            },
            {
                boolComparison:
                    (account.newPassword && !account.oldPassword) ||
                    (!account.newPassword && account.oldPassword),
                errorMsg: AccountErrors.PASS_ERR,
                inputReference: references.oldPass,
            },
            {
                boolComparison:
                    account.oldPassword && account.newPassword === account.oldPassword,
                errorMsg: AccountErrors.SAMEPASS_ERR,
                inputReference: references.newPass,
            },
        ];
    }

    static getRegistrationRules(register) {
        return [
            {
                boolComparison:
                    invalidFieldLength(register.firstName, 90) ||
                    invalidRegisterCharacters(register.firstName) ||
                    /[^a-zA-Z-' ]+$/.test(register.firstName || ""),
                errorMsg: RegistrationErrors.FIRST_NAME_ERR,
                inputReference: null,
            },
            {
                boolComparison:
                    invalidFieldLength(register.lastName, 90) ||
                    invalidRegisterCharacters(register.lastName) ||
                    /[^a-zA-Z-' ]+$/.test(register.lastName || ""),
                errorMsg: RegistrationErrors.LAST_NAME_ERR,
                inputReference: null,
            },
            {
                boolComparison: !validator.isEmail(register.email || ""),
                errorMsg: RegistrationErrors.EMAIL_ERR,
                inputReference: null,
            },
            {
                boolComparison:
                    invalidFieldLength(register.state, 4) ||
                    invalidCharacters(register.state),
                errorMsg: RegistrationErrors.STATE_ERR,
                inputReference: null,
            },
            {
                boolComparison: register.state === "OR"
                    && (sessionStorage.getItem('choices-referring-domainUrl') === "https://my.bankerslife.com/"
                        || sessionStorage.getItem('choices-referring-domainUrl') === "https://uat-my.bankerslife.com"
                        || sessionStorage.getItem('choices-referring-domainUrl') === "https://genhealthy.com/extra-perks"),
                errorMsg: RegistrationErrors.NO_OREGON_STATE_ERR,
                inputReference: null,
            },
            {
                boolComparison: !this.enforcePasswordRules(register.password),
                errorMsg: RegistrationErrors.PASS_ERR,
                inputReference: null,
            },
            {
                boolComparison: register.confirmPassword !== register.password,
                errorMsg: RegistrationErrors.CONFIRMPASS_ERR,
                inputReference: null,
            },
        ];
    }

    static violatesBusinessRules(ruleArray, action = null) {
        let isBusinessRuleViolation = false;

        ruleArray.forEach((rule) => {
            if (rule.boolComparison) {
                if (action) action(rule.errorMsg);
                if (rule.inputReference) rule.inputReference.current.focus();
                isBusinessRuleViolation = rule.boolComparison;
            }
        });

        return isBusinessRuleViolation;
    }

    static enforcePasswordRules(newPassword) {
        // 8 chars, has uppercase, lowercase, number chars
        const pattern = new RegExp(
            "^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).*$"
        );
        return pattern.test(newPassword);
    }

    static nameEmailOrCommunicationPreferenceHasChanged(
        accountEmail,
        communicationPreferenceHasChanged,
        firstName,
        lastName
    ) {
        return (
            communicationPreferenceHasChanged ||
            (accountEmail &&
                (accountEmail !== localStorage.getItem("choices-email") ||
                    firstName !== localStorage.getItem("choices-firstName") ||
                    lastName !== localStorage.getItem("choices-lastName")))
        );
    }

    static passwordHasChanged(oldPassword, newPassword, confirmedPassword) {
        return oldPassword && newPassword && confirmedPassword;
    }

    static getCommunicationConsent() {
        const communicationConsent = JSON.parse(
            localStorage.getItem("choices-communication-consent")
        );
        // Opt the user in if they have not deselected the input checkbox
        return communicationConsent !== null ? communicationConsent : true;
    }

    static isValidEmail(email) {
        return validator.isEmail(email) && email.length <= 90;
    }

    static isSilverSneakersMember() {
        const SILVERSNEAKERS_CLIENT_ID = "6";// Hardcoded SS ClientId
        const userClientId = localStorage.getItem("choices-clientId");
        return (userClientId !== null && userClientId === SILVERSNEAKERS_CLIENT_ID);
    }

    //static async subscribe(email, isSubscribed) {
    //  let subscriptionEndpoint = `${EnvironmentService.getEnvironmentVariable(
    //    "IDENTITY_API"
    //  )}/api/accounts/subscribe/${email}/${isSubscribed}`;
    //  return await axios.get(subscriptionEndpoint).catch((error) => error);
    //}

    static isLooseNullOrUndefined(value) {
        // eslint-disable-next-line eqeqeq
        return (
            value === undefined ||
            value === null ||
            value === "null" ||
            value === "undefinied"
        );
    }
}
