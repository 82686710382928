/* eslint-disable indent */
import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useNavigate } from "react-router-dom";
import { FormTitle } from "../../components/form/Form";
import ActionButton from "../../components/action-button/ActionButton";
import styles from "./RegisterForm.module.scss";
import Loading from "../../components/loading/Loading";
import RegisterName from "./RegisterName";
import StateOfResidenceInput from "../stateOfResidence/StateOfResidenceInput";
import RegisterPassword from "./RegisterPassword";
import RegisterEmail from "./RegisterEmail";
import RegistrationErrors from "../../models/registrationErrors";
import AccountService from "../../services/account.service";
import OAuthService from "../../services/oauth.service";
import AnalyticsService from "../../services/analytics.service";
import { useChoicesContext } from "../../context/ChoicesContext";
import RegisterError from "./RegisterError";
import { useGetClientByIdQuery } from "../../services/react-query/choices.service";
import ToastError from "../toast/ToastError";

const RegisterForm = () => {
    const navigate = useNavigate();
    const { loginWithRedirect } = useAuth0();
    const { setFirstName, setLastName, setEmail, setState } = useChoicesContext();

    const [signUpFirstName, setSignUpFirstName] = useState("");
    const [signUpLastName, setSignUpLastName] = useState("");
    const [signUpEmail, setSignUpEmail] = useState("");
    const [stateName, setStateName] = useState("");
    const [stateAbbreviation, setStateAbbreviation] = useState("");
    const [signUpPassword, setSignUpPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [registrationErrors, setRegistrationErrors] = useState([]);
    const [displayErrors, setDisplayErrors] = useState(false);
    const [toasts, setToasts] = useState([]);
    const { data: clientData, error: clientError, isLoading: isGettingClient } = useGetClientByIdQuery(
        sessionStorage.getItem("choices-referring-clientId")
    );

    useEffect(() => {
        const isAllowedToRegister = OAuthService.verifyReferralSource();
        if (!isAllowedToRegister) navigate("/access-denied");
    }, [navigate]);

    const validateForm = (newUser) => {
        let errors = [];
        const addError = (err) => errors.push(err);

        const registrationRules = AccountService.getRegistrationRules(newUser);
        if (AccountService.violatesBusinessRules(registrationRules, addError)) {
            setRegistrationErrors([...errors]);
            return errors;
        }
        return [];
    };

    const handleCreateUser = async (newUser) => {
        try {
            const accountRequest = await AccountService.createAuth0User(newUser);
            return accountRequest;
        } catch (error) {
            return { error: true, message: error.message };
        }
    };

    const handleSuccess = () => {
        setFirstName(signUpFirstName);
        setLastName(signUpLastName);
        setEmail(signUpEmail);
        setState(stateAbbreviation);

        if (clientData && !clientError) localStorage.setItem("choices-client-name", clientData.name);

        AnalyticsService.trackRegistration(clientData.name, signUpEmail);
        AnalyticsService.trackSignUp();
        loginWithRedirect();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setRegistrationErrors([]);
        setDisplayErrors(false);

        const newUser = {
            firstName: signUpFirstName,
            lastName: signUpLastName,
            email: signUpEmail,
            state: stateAbbreviation,
            password: signUpPassword,
            confirmPassword: confirmPassword,
            clientId: sessionStorage.getItem("choices-referring-clientId"),
            domainId: sessionStorage.getItem("choices-referring-domainId"),
            domain_url: sessionStorage.getItem("choices-referring-domainUrl"),
            isSubscribed: false
        };

        const errors = validateForm(newUser);
        if (errors.length > 0) {
            setToasts(errors.map((m, index) => ({ message: m, success: true, key: index })));
            return setDisplayErrors(true);
        }

        const accountRequest = await handleCreateUser(newUser);

        if (accountRequest?.error) {
            const errorMessages = [{ message: accountRequest.message, success: false, key: 0 }];
            setToasts(errorMessages);
            return setDisplayErrors(true);
        }

        if (accountRequest && accountRequest.name === signUpEmail) {
            handleSuccess();
        } else {
            setToasts([RegistrationErrors.REGISTER_ERR]);
            setDisplayErrors(true);
        }
    };

    return (
        <form className={styles.form} onSubmit={handleSubmit} onClickCapture={() => setDisplayErrors(false)}>
            <FormTitle title="Join Choices" />
            <section className={styles.formInputs}>
                {displayErrors && registrationErrors.length > 0 && (
                    <RegisterError
                        displayErrors={displayErrors}
                        setDisplayErrors={setDisplayErrors}
                        registrationErrors={registrationErrors}
                    />
                )}
                <RegisterName
                    signupFirstName={signUpFirstName}
                    signupLastName={signUpLastName}
                    setSignUpFirstName={setSignUpFirstName}
                    setSignUpLastName={setSignUpLastName}
                />
                <RegisterEmail signUpEmail={signUpEmail} setSignUpEmail={setSignUpEmail} />
                <StateOfResidenceInput
                    stateName={stateName}
                    setStateName={setStateName}
                    stateAbbreviation={stateAbbreviation}
                    setStateAbbreviation={setStateAbbreviation}
                />
                <RegisterPassword
                    signUpPassword={signUpPassword}
                    setSignUpPassword={setSignUpPassword}
                    confirmPassword={confirmPassword}
                    setConfirmPassword={setConfirmPassword}
                />
                <menu className={styles.formMenu}>
                    <div className={styles.formBtns}>
                        <ActionButton btnType="submit">Sign Up</ActionButton>
                    </div>
                    <div className={styles.linkDisclaimer}>
                        <Link to="/login">Have an account? Login</Link>
                    </div>
                </menu>
            </section>
            <ToastError messages={toasts} isSuccessful={false} />
            <Loading isLoading={isGettingClient} isFullScreen />
        </form>
    );
};

export default RegisterForm;
